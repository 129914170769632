<template>
  <h1>Please wait....</h1>
</template>
<script>
import { defineComponent, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "login-via-code",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    onBeforeMount(() =>
      store
        .dispatch("loginViaCode", {
          agent64: decodeURIComponent(route.params.agent),
        })
        .then(() => {
          // Go to page after successfully login
          router.replace({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: t("Login.Failed"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("TryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
    );
  },
});
</script>
